import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;

  padding-left: 29px;

  width: 100%;
  max-width: 1380px;

  height: fit-content;
  min-height: 300px;

  background-color: ${colors.blue100};

  h2 {
    color: ${colors.gray600};
    font-size: 32px;
    font-weight: 400;

    line-height: 48px;
  }

  p {
    font-size: 16px;
    max-width: 421px;
    line-height: 20px;
    margin-top: 4px;
  }

  a {
    margin-top: 22px;
    background-color: ${colors.blue400};

    outline: none;

    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;
    max-width: 193px;

    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 750px) {
    padding-left: 32px;
  }

  @media (max-width: 650px) {
    justify-content: center;

    p {
      max-width: 328px;
      margin-top: 4px;
    }

    a {
      margin-top: 22px;
    }
  }

  @media (max-width: 500px) {
    padding-left: 16px;
  }

  @media (max-width: 499px) {
    h2 {
      font-size: 24px;
      line-height: 40px;
    }

    p {
      max-width: 250px;
    }
  }
`;
