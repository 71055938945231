import React from 'react';
import { Section, SectionRowBottom, SectionRowTop } from './styles';

import IconSegurancaCaixa from '@/images/paginas/seguranca-e-tecnologia/icon-seguranca-carga.svg';
import IconSegurancaCaminhao from '@/images/paginas/seguranca-e-tecnologia/icon-seguranca-caminhao.svg';
import IconVolumeCheck from '@/images/paginas/seguranca-e-tecnologia/icon-volume-check.svg';
import IconTorreDeControle from '@/images/paginas/seguranca-e-tecnologia/icon-torre-de-controle.svg';
import IconAtendenteVirtual from '@/images/paginas/seguranca-e-tecnologia/icon-atendente-virtual.svg';
import IconRoteirização from '@/images/paginas/seguranca-e-tecnologia/icon-roteirização.svg';

const SectionComponent = (): JSX.Element => {
  return (
    <Section>
      <SectionRowTop>
        <div>
          <img
            src={IconSegurancaCaixa}
            alt="Ícone de escudo representando segurança da mercadoria"
          />
          <p>
            Contamos com o trabalho de empresas especializadas em seguros de
            mercadorias
          </p>
        </div>
        <div>
          <img
            src={IconSegurancaCaminhao}
            alt="Ícone de escudo representando segurança dos caminhões"
          />
          <p>
            Caminhões são monitorados e equipados com sistema de bloqueio e
            alertas de risco
          </p>
        </div>

        <div>
          <img src={IconVolumeCheck} alt="Ícone de rastreabilidade do volume" />
          <p>
            Todos os volumes são etiquetados com código de barras, permitindo a
            rastreabilidade.
          </p>
        </div>
      </SectionRowTop>
      <SectionRowBottom>
        <div>
          <img
            src={IconTorreDeControle}
            alt="Ícone simbolizando torre de controle"
          />
          <p>
            Torre de controle trabalha de forma online, com dashboards e
            diversas tecnologias, que possibilitam o foco no cumprimento dos
            prazos
          </p>
        </div>

        <div>
          <img
            src={IconAtendenteVirtual}
            alt="Ícone de celular simbolizando atendimento virtual"
          />
          <p>
            Atendente Virtual onde o cliente tem acesso a todas as informações e
            serviços
          </p>
        </div>

        <div>
          <img
            src={IconRoteirização}
            alt="Ícone de computador simbolizando roteirização computadorizada"
          />
          <p>
            Roteirização computadorizada para facilitar o manuseio e a
            classificação
          </p>
        </div>
      </SectionRowBottom>
    </Section>
  );
};

export default SectionComponent;
