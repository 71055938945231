import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import SEO from '@/components/SEO';

import HeroSection from '@/components/Pages/SegurancaETecnologia/HeroSection/';
import Section from '@/components/Pages/SegurancaETecnologia/Section';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  BreadCrumbsWrapper,
} from '@/styles/pages/seguranca-e-tecnologia';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'A Generoso' },
  { name: 'Segurança e Tecnologia' },
];

const SegurancaETecnologiaPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "seguranca-e-tecnologia" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Segurança e tecnologia"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>
            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>
            <SectionWrapper>
              <Section />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default SegurancaETecnologiaPage;
