import React from 'react';

import { HeroSection } from './styles';

import RedirectNewPage from '@/images/icons/icon-redirect-new-page-white.svg';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <h2>Segurança e tecnologia</h2>
      <p>
        Investimos constantemente em segurança e tecnologia para que o
        transporte das mercadorias seja mais rápido, eficaz e seguro.
      </p>
      <a
        href="https://cliente.generoso.com.br/coleta/coletar"
        target="_blank"
        rel="noreferrer"
      >
        Agendar coleta
        <img src={RedirectNewPage} alt="Seta de nova página" />
      </a>
    </HeroSection>
  );
};

export default HeroSectionComponent;
