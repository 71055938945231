import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1006px;

  margin: 93px 0;

  @media (max-width: 952px) {
    align-items: center;
    justify-content: center;
    max-height: 100%;
  }

  @media (max-width: 650px) {
    margin-top: 54px;
    margin-bottom: 54px;
  }
`;

export const SectionRowTop = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    width: 100%;
    max-width: 270px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  p {
    margin-top: 38px;
    font-weight: 500;
    color: #7b7e88;
  }

  @media (max-width: 952px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:first-child {
      img {
        margin-top: 0;
      }
    }

    img {
      margin-top: 54px;
    }
  }

  @media (max-width: 320px) {
    font-size: 12px;

    p {
      margin-top: 19px;
    }

    img {
      margin-top: 27px;
    }
  }
`;

export const SectionRowBottom = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 84px;

  div {
    width: 100%;
    max-width: 270px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    height: 100%;
  }

  p {
    margin-top: 38px;
    font-weight: 500;
    color: #7b7e88;
  }
  @media (max-width: 952px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;

    img {
      margin-top: 54px;
    }
  }
  @media (max-width: 320px) {
    font-size: 12px;

    p {
      margin-top: 19px;
    }

    img {
      margin-top: 27px;
    }
  }
`;
